<template>
	<div>
		<search-header></search-header>

		<div v-if="detail && isLoading" class="container p-0">
		  <div class="row my-3">
			<nav class="col-12 p-0">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/news">农村综合产权交易服务平台</router-link></li>
					<li class="breadcrumb-item" v-for="m,i in getMap" :key="i"><router-link :to="m.url">{{m.text}}</router-link></li>
				</ol>
        	</nav>
			<div class="col-12 p-0 f-14">
				<h4>{{detail.title}}</h4>
				<div class="mt-2 d-flex align-items-center text-secondary">
					<span>需求编码: {{detail.id}}</span>
					<span v-if="detail.updateAt || detail.createAt" class="ml-5">更新时间: {{detail.updateAt ? detail.updateAt : detail.createAt}}</span>
					<img class="ml-5" src="@/assets/icon/eye-open.png">
					<span class="ml-1">{{detail.lookNum}} 次查看</span>
				</div>
			</div>

			<div class="col-12 mt-2 p-0">
				<div class="row m-0">
					<div class="col border border-right-0 p-3">
						<div class="border-bottom pb-3">
							<div class="text-secondary">地区</div>
							<div>{{getAreaValue(detail.area)}}</div>
						</div>
						<div class="border-bottom mt-3 pb-3 clearfix">
							<div class="float-left w-50">
								<div class="text-secondary">土地用途</div>
								<div>{{getMap[getMap.length -1].name}}</div>
							</div>
							<div class="float-left w-50">
								<div class="text-secondary">需求面积</div>
								<div>{{detail.measureArea}} {{getAcreageUnitValue(detail.acreageUnit)}}</div>
							</div>
						</div>
						<div class="mt-3  clearfix">
							<div class="float-left w-50">
								<div class="text-secondary">流转年限</div>
								<div>{{detail.year}} 年</div>
							</div>
							<div class="float-left w-50">
								<div class="text-secondary">流转方式</div>
								<div>{{getNeedsTypeValue(detail.needsType)}}</div>
							</div>
						</div>
					</div>
					<div class="col border p-3">
						<div class="pb-4 pt-3 border-bottom clearfix">
							<div class="float-left w-50">
								<div class="text-secondary">期望价格</div>
							</div>
							<div class="float-right  w-50">
								<div v-if="detail.minPrice > 0 && detail.maxPrice > 0" class="text-secondary text-right"> 
									<b class="text-danger">{{detail.minPrice}} ~ {{detail.maxPrice}}</b>
									<span> {{getPriceUnitValue(detail.priceUnit)}}</span>
								</div>
								<div v-else class="text-danger text-right"> 
									面议
								</div>
							</div>
						</div>
						<div class="pb-3 pt-3 border-bottom clearfix">
							<div class="float-left w-50">
								<div class="text-secondary">联系方式</div>
								<div>{{detail.contacts}}</div>
							</div>
							<div class="float-right w-50">
								<div class="text-secondary text-right mt-3"> 
									<a href="javascript:;" @click="isShowPhone = true" v-if="!isShowPhone" class="text-success">查看联系电话</a>
									<span v-else class="text-success">0794-8269907</span>
								</div>
							</div>
						</div>
						<div class="pt-3 clearfix">
							<div class="mt-1">
								<b-button variant="success">预约回电</b-button>
							</div>
						</div>
					</div>
					<div v-if="resembleNeedsList.length > 0" class="col p-0 pl-3">
						<div class="bg-white border pl-3 pr-3">
							<div class="h6 mt-3 mb-2">相似需求推荐</div>
							<div v-for="m,i in resembleNeedsList" :key="i" class="text-truncate pt-2 pb-2" :class="{'border-bottom' : i +1 < resembleNeedsList.length}">
								<router-link :to="'/needs/detail?id=' + m.id" target="_blank">{{m.title}}</router-link>
							</div>
						</div>
					</div>
					<div v-if="detail.content" class="col-12 border mt-3 p-3">
						<div class="text-secondary">详细说明</div>
						<div>{{detail.content}}</div>
					</div>
				</div>
			</div>
		  </div>
		</div>
		
		<div v-if="isLoading" class="container my-4 p-0">
			<div class="row">
				<div class="col-12 border-left menu-title px-3 mb-2 py-0">
					热门地源
				</div>
				<div class="col-2 mt-2 mb-3 p-0" :class="{ 'pl-2': i > 0 }" v-for="m,i in hotLandList" :key="m.id">
                  <land-right :datas="m" :height="150"></land-right>
                </div>
				<div class="col-12 border-left menu-title px-3 mb-2 py-0">
					猜你喜欢
				</div>
				<div class="col-2 mt-2 mb-3 p-0" :class="{ 'pl-2': i > 0 }" v-for="m,i in likeLandList" :key="m.id">
                  <land-right :datas="m" :height="150"></land-right>
                </div>
			</div>
		</div>
		<map-footer></map-footer>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import LandRight from "@/components/right/land-right.vue";
	export default {
		components: {
			LandRight
		},
	  	data() {
			return {
				detail: null,
				hotLandList:[],
				likeLandList:[],
				resembleNeedsList:[],
				isShowPhone: false,
				isLoading: false,
			}
		},
		watch: {
			'getMap': 'getResembleNeeds'
		},
		computed: {
			...mapGetters(["area","dict","user"]),
			getAreaValue()	{
				return function(code) {
					let value = '';
					if(this.area) {
						let a = this.area.area.find(x => x.code == code)
						if(a) {
							value = a.name;
							let c = this.area.city.find(x => x.code == a.parentCode);
							if(c) {
								value = value + '/' + c.name;
								let p = this.area.province.find(x => x.code == c.parentCode);
								if(p) {
									value = value + '/' + p.name;
								}
							}
						}
					}
					return value.replace(' 市辖区','')
				}
			},
			getMap() {
				let arr = [];
				if(this.area && this.dict && this.dict.landUseType && this.detail) {
					let a = null,c = null, p = null;
					a = this.area.area.find(x => x.code == this.detail.area)
					if(a) {
						c = this.area.city.find(x => x.code == a.parentCode);
						if(c) {
							p = this.area.province.find(x => x.code == c.parentCode);
						}
					}
					if(p != null) {
						arr.push({
							id: p.code,
							text: p.name + '土地',
							tag: 'province',
							url: '/needs?province=' + p.code
						})
					}
					if(c != null && c.name != '市辖区') {
						arr.push({
							id: c.code,
							text: c.name + '土地',
							tag: 'city',
							url: '/needs?province='+p.code+'&city=' + c.code
						})
					}
					if(a != null) {
						arr.push({
							id: a.code,
							text: a.name + '土地',
							tag: 'area',
							url: '/needs?province='+p.code+'&city=' + c.code + '&area=' + a.code
						})
					}
					
					let lp = null, lc = null;
					lp = this.dict.landUseType.find(x => x.children.find(y=> y.id  == this.detail.classId));
					if(lp) {
						let url = arr[arr.length-1].url;
						arr.push({
							id: lp.id,
							text: (a.name ? a.name : '') + lp.text,
							name: lp.text,
							tag: 'lp',
							url: url + '&classId=' + lp.id
						})
						lc = lp.children.find(x=> x.id == this.detail.classId);
						if(lc) {
							arr.push({
								id: lc.id,
								text: (a.name ? a.name : '') + lc.text,
								name: lc.text,
								tag: 'lc',
								url: url + '&classid='+ lp.id +'&childclassid=' + lc.id
							})
						}
					}
					
				}
				
				return arr;
			},
			getAcreageUnitValue() {
				return function(id) {
					if(this.dict && this.dict.acreageUnit) {
						let m = this.dict.acreageUnit.find(x => x.id == id)
						if(m) {
							return m.text;
						}
					}
					return ''
				}
			},
			getPriceUnitValue () {
				return function(id) {
					if(this.dict && this.dict.priceUnit) {
						let m = this.dict.priceUnit.find(x => x.id == id)
						if(m) {
							return m.text;
						}
					}
					return ''
				}
			},
			getNeedsTypeValue () {
				return function(id) {
					if(this.dict && this.dict.needsType) {
						let m = this.dict.needsType.find(x => x.id == id)
						if(m) {
							return m.text;
						}
					}
					return ''
				}
			}

		},
		methods:{
			getDetail() {
				this.$http.post(this.$api.supply.getNeedsDetail + '/' + this.$route.query.id).then((res) => {
					this.detail = res.result;
				});
			},
			getHotLandList() {
			    this.$http.post(this.$api.supply.getNeedsForLetList, {
					param: {}, 
					size: 6,
					current: 1 
				}).then((res) => {
					this.hotLandList = res.result.records.map(x=> {
						let f = x.userNeedsFiles.find(y=> y.uploadType == 1);
						return {
							id: x.id,
							fileUrl: f ? f.fileUrl : '',
							title: x.title,
							price: x.price,
							priceUnit: x.priceUnit,
							measureArea: x.measureArea,
							acreageUnit: x.acreageUnit,
						}
					});
				});
			},
			getLikeLandList() {
				this.$http.post(this.$api.supply.getNeedsForLetList, {
					param: {}, 
					size: 6,
					current: 3 
				}).then((res) => {
					this.likeLandList = res.result.records.map(x=> {
						let f = x.userNeedsFiles.find(y=> y.uploadType == 1);
						return {
							id: x.id,
							fileUrl: f ? f.fileUrl : '',
							title: x.title,
							price: x.price,
							priceUnit: x.priceUnit,
							measureArea: x.measureArea,
							acreageUnit: x.acreageUnit,
						}
					});
				});
			},
			getResembleNeeds() {
				let maps = this.getMap;
				if(maps.length > 0) {
					let area = maps.find(x=> x.tag == 'province')
					let classes = maps.find(x=> x.tag == 'lp')
					this.$http.post(this.$api.supply.getNeedsList, {	param:{
						area: area ? area.id : '',
						classId: classes ? classes.id : ''
					},
					size: 5,
					current: 1}).then(res => {
						this.resembleNeedsList = res.result.records;
						this.isLoading = true;
					})
				}
			}
		},
		created() {
			if(this.$route.query.id > 0) {
				this.getDetail();
				this.getHotLandList();
				this.getLikeLandList();
			}
			
		}
	}
</script>

<style>
</style>
